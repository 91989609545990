<template>
  <!-- <v-parallax
    dark
    src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
    height="100vh"
  > -->
  <v-parallax dark src="./assets/bg.jpg" height="100vh">
    <v-app>
      <!-- <span class="bg-image"></span> -->
      <!-- <v-parallax class="bg-image" src="./assets/bg.jpeg" dark> </v-parallax> -->
      <v-app-bar app color="primary" dark>
        <div class="d-flex align-center">
          <!-- <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="./assets/logo.png"
            transition="scale-transition"
            width="40"
          /> -->
          <span class="d-none d-lg-flex">{{
            this.$store.state.listClub[0]==undefined?"":this.$store.state.listClub[0].club_name_eng
          }}</span>
        </div>

        <v-spacer></v-spacer>
        <!-- <router-link to="About">
          <v-btn text> เกี่ยวกับเรา </v-btn>
        </router-link> -->
        <page-login-vue
          class="mr-2"
          v-if="this.$store.state.stateOnline == 'offline'"
        ></page-login-vue>
        <list-menu v-else></list-menu>
      </v-app-bar>

      <v-main>
        <router-view></router-view>
      </v-main>
      <Cloading></Cloading>
    </v-app>
  </v-parallax>
</template>

<script>
import Cloading from "./components/Cloading";
import PageLoginVue from "./components/PageLogin.vue";
import ListMenu from "./components/ListMenu.vue";
export default {
  name: "App",
  components: {
    Cloading,
    PageLoginVue,
    ListMenu,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.theme--dark,
.v-application {
  background: transparent !important;
  color: #ffffff;
}
</style>
<style>
.v-application .headline {
  font-family: "Niramit", sans-serif !important;
}
.v-text-field input,
.v-input .v-label,
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  height: 30px;
  line-height: 25px;
  top: 0px;
}
.v-select__selection {
  height: 25px;
  line-height: 25px;
  margin: 0 !important;
}
.v-select__selection--comma {
  margin: 0 !important;
}
</style>
