import Vue from "vue";
// import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import loading from "./plugins/Cloading";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;
// interceptorsSetup();
window.$ = window.jQuery = require("jquery");
window.Swal = require("sweetalert2");
// var apiPath = "";
new Vue({
  router,
  store,
  vuetify,
  // loading,
  icons: {
    iconfont: "mdiSvg", // default - only for display purposes
  },
  render: (h) => h(App),
  methods: {
    setUp() {
      window.$.ajaxSetup({
        beforeSend: function(xhr, settings) {
          xhr;
          settings.url;
          if (store.state.statShowLoading == true) {
            store.state.showLoading = true;
          }
          if (settings.url != store.state.pathAPI + "member/checkLogin") {
            if (store.getters.checkLogin.mem_id == undefined) {
              router.push("/");
            } else {
              //Object.assign(settings.data, store.getters.checkLogin);
            }
          }
        },
        dataFilter: function(data) {
          store.state.showLoading = false;
          return data;
        },
      });
      store.dispatch("getlistClub");
    },
  },
  mounted() {
    this.setUp();
  },
}).$mount("#app");
