<template>
  <div class="row d-flex justify-end" id="listMenu">
    <div class="mr-2">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="warning" dark v-bind="attrs" v-on="on">
            เมนูจัดการข้อมูล <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list rounded>
          <v-subheader><v-icon class="mr-2">mdi-cogs</v-icon>Manage</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item to="/period">
              <v-list-item-icon><v-icon> mdi-periodic-table</v-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>กำหนดเงินค่างวด</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/villate">
              <v-list-item-icon><v-icon> mdi-home-account</v-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ข้อมูลหมู่บ้าน</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/member">
              <v-list-item-icon><v-icon> mdi-account-plus</v-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ข้อมูลสมาชิก</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/member_lose">
              <v-list-item-icon> <v-icon> mdi-account-cancel</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ข้อมูลสมาชิกพ้นสภาพ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/member_pay">
              <v-list-item-icon><v-icon> mdi-cash-usd</v-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ข้อมูลการเก็บเงิน</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              class="mb-2"
              v-if="
                this.$store.getters.checkLogin.ad_type == 9 ||
                this.$store.getters.checkLogin.ad_type == 8
              "
            ></v-divider>
            <v-list-item
              to="/admin"
              v-if="
                this.$store.getters.checkLogin.ad_type == 9 ||
                this.$store.getters.checkLogin.ad_type == 8
              "
            >
              <v-list-item-icon
                ><v-icon> mdi-card-account-details-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ข้อมูลเจ้าหน้าที่</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mb-2"></v-divider>
            <v-list-item @click="dialog_frm_changepass = true">
              <v-list-item-icon><v-icon> mdi-lock-question</v-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>เปลี่ยนรหัสผ่าน</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
    <Frm_changepass
      :dialog_frm_changepass="dialog_frm_changepass"
      @callBackChangePass="callBackChangePass"
    ></Frm_changepass>
    <div class="text-center mr-2">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="success" dark v-bind="attrs" v-on="on">
            รายงานข้อมูล <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list rounded>
          <v-subheader><v-icon class="mr-2">mdi-printer</v-icon>Report</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item to="/report_member">
              <v-list-item-icon><v-icon> mdi-badge-account</v-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ข้อมูลสมาชิก</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/report_member_lose">
              <v-list-item-icon>
                <v-icon> mdi-badge-account-alert</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title>ข้อมูลสมาชิกพ้นสภาพ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              ><v-icon class="mr-2">mdi-printer</v-icon
              >รายงานข้อมูลการเก็บเงิน</v-subheader
            >
            <v-list-item to="/report_member_pay_locality">
              <v-list-item-icon><v-icon> mdi-account-cash</v-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>รายงานการเก็บเงิน (ตำบล)</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/report_member_pay_villate">
              <v-list-item-icon><v-icon> mdi-account-cash</v-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>รายงานการเก็บเงิน (หมู่บ้าน)</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://www.maeainetworkcommittee.com/report/sumscore.php">
              <v-list-item-icon><v-icon> mdi-account-cash</v-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>รายงานสรุปข้อมูลรวม</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
    <div class="text-center mr-2">
      <v-btn color="error" to="/" dark @click="logout">
        <v-icon>mdi-logout</v-icon> Logout
      </v-btn>
    </div>
  </div>
</template>

<script>
import Frm_changepass from "./frm_changepass";
export default {
  components: { Frm_changepass },
  data() {
    return {
      dialog_frm_changepass: false,
    };
  },
  methods: {
    logout() {
      window.Swal.fire("Success", "ออกจากระบบเรียบร้อย!!!", "success");
      this.$store.state.stateOnline = "offline";
      this.$store.state.listUserLogin = {};
    },
    callBackChangePass() {
      this.dialog_frm_changepass = false;
    },
  },
};
</script>

<style></style>
