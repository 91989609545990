<template>
  <div id="loading">
    <v-dialog v-model="this.$store.state.showLoading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          กำลังประมวลผล ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "loading",
};
</script>

<style></style>
