<template>
  <div class="text-center" id="frm_changepass">
    <v-dialog v-model="dialog_frm_changepass" width="500">
      <form @submit.prevent="submitData()">
        <v-card>
          <v-card-title class="font-main grey lighten-2">
            <v-icon>mdi-key-chain</v-icon> เปลี่ยนรหัสผ่าน
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="dataFrm.str_password1"
              type="password"
              label="รหัสผ่าน"
              prepend-inner-icon="mdi-lock"
              required
            ></v-text-field>
            <v-text-field
              type="password"
              v-model="dataFrm.str_password2"
              label="ยืนยันรหัสผ่าน"
              prepend-inner-icon="mdi-lock"
              required
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info"
              type="submit"
              :disabled="
                dataFrm.str_password1 == dataFrm.str_password2 &&
                dataFrm.str_password1 != ''
                  ? false
                  : true
              "
              >ยืนยันเปลี่ยนรหัสผ่าน</v-btn
            >
            <v-btn @click="clear">ปิด</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "frm_changepass",
  data: () => ({
    dataFrm: {
      str_password1: "",
      str_password2: "",
    },
  }),
  props: {
    dialog_frm_changepass: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submitData() {
      let vData = {};
      Object.assign(vData, this.$data, this.$store.getters.checkLogin);
      window.$.post(
        this.$store.state.pathAPI + "member/changePass",
        vData,
        (param) => {
          window.Swal.fire(param.title, param.html, param.icon);
          this.clear();
        },
        "json"
      );
    },
    clear() {
      this.str_password1 = "";
      this.str_password2 = "";
      this.$emit("callBackChangePass");
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--dark,
.v-application {
  background: white !important;
  color: #ffffff;
}
</style>
