import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/club",
    name: "club",
    component: () => import("../views/club.vue"),
  },
  {
    path: "/villate",
    name: "villate",
    component: () => import("../views/villate.vue"),
  },
  {
    path: "/member",
    name: "member",
    component: () => import("../views/Member.vue"),
  },
  {
    path: "/member_lose",
    name: "member_lose",
    component: () => import("../views/member_lose.vue"),
  },
  {
    path: "/member_pay",
    name: "member_pay",
    component: () => import("../views/member_pay.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin.vue"),
  },
  {
    path: "/period",
    name: "period",
    component: () => import("../views/period.vue"),
  },
  {
    path: "/report_member",
    name: "report_member",
    component: () => import("../views/report_member.vue"),
  },
  {
    path: "/report_member_lose",
    name: "report_member_lose",
    component: () => import("../views/report_member_lose.vue"),
  },
  {
    path: "/report_member_pay_locality",
    name: "report_member_pay_locality",
    component: () => import("../views/report_member_pay_locality.vue"),
  },
  {
    path: "/report_member_pay_villate",
    name: "report_member_pay_villate",
    component: () => import("../views/report_member_pay_villate.vue"),
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
