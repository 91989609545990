import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";
// import _axios from "_axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pathAPI: "https://maeai.big24u.com/api/",
    statShowLoading: true,
    showLoading: false,
    stateOnline: "offline",
    DefPer: "",
    listPeriod: [],
    listPeriodAll: [],
    listClub: [],
    listMember: [],
    listMemberReport: [],
    listMemberLose: [],
    listMemberPayLocality: [],
    listMemberAdmin: [],
    listUserLogin: {},
    listPrename: {},
    listProvince: {},
    listDistrict: {},
    listLocality: {},
    listVillate: {},
    listMemberType: {},
    listMemberStatus: {},
    listMemberHeadline: {},
    _getlistPeriodOpen: {},
  },
  mutations: {
    getDefPeriod(state) {
      window.$.get(
        state.pathAPI + "DB/DefPeriodJson",
        (param) => {
          state.DefPer = param[0].defPer;
        },
        "json"
      );
    },
    getlistPeriodAll(state) {
      window.$.get(
        state.pathAPI + "DB/List_Period",
        (param) => {
          state.listPeriodAll = param;
        },
        "json"
      );
    },
    getlistPeriod(state) {
      window.$.get(
        state.pathAPI + "DB/List_Period_Close",
        (param) => {
          state.listPeriod = param;
        },
        "json"
      );
    },
    getlistClub(state) {
      window.$.get(
        state.pathAPI + "DB/List_Club",
        (param) => {
          state.listClub = param;
        },
        "json"
      );
    },
    getlistMember(state, limit) {
      window.$.post(
        state.pathAPI + "DB/list_Member",
        { type: limit },
        (param) => {
          state.listMember = param;
        },
        "json"
      );
    },
    getlistMemberReport(state) {
      window.$.get(
        state.pathAPI + "report/member",
        (param) => {
          state.listMemberReport = param;
        },
        "json"
      );
    },
    getlistMemberAdmin(state) {
      window.$.get(
        state.pathAPI + "DB/list_MemberAdmin",
        (param) => {
          state.listMemberAdmin = param;
        },
        "json"
      );
    },
    getlistMemberLose(state) {
      window.$.get(
        state.pathAPI + "DB/list_MemberLose",
        (param) => {
          state.listMemberLose = param;
        },
        "json"
      );
    },
    getlistMemberPayLocality(state) {
      window.$.get(
        state.pathAPI + "report/member_pay_locality",
        (param) => {
          state.listMemberPayLocality = param;
        },
        "json"
      );
    },
    getlistPrename(state) {
      window.$.get(
        state.pathAPI + "DB/List_Prename",
        (param) => {
          state.listPrename = param;
        },
        "json"
      );
    },
    getlistProvince(state) {
      window.$.get(
        state.pathAPI + "DB/List_Province",
        (param) => {
          state.listProvince = param;
        },
        "json"
      );
    },
    getlistDistrict(state) {
      window.$.get(
        state.pathAPI + "DB/List_District",
        (param) => {
          state.listDistrict = param;
        },
        "json"
      );
    },
    getlistLocality(state) {
      window.$.get(
        state.pathAPI + "DB/List_Locality",
        (param) => {
          state.listLocality = param;
        },
        "json"
      );
    },
    getlistVillate(state) {
      window.$.get(
        state.pathAPI + "DB/List_Villate",
        (param) => {
          state.listVillate = param;
        },
        "json"
      );
    },
    getlistMemberType(state) {
      window.$.get(
        state.pathAPI + "DB/List_MemberType",
        (param) => {
          state.listMemberType = param;
        },
        "json"
      );
    },
    getlistMemberStatus(state) {
      window.$.get(
        state.pathAPI + "DB/List_MemberStatus",
        (param) => {
          state.listMemberStatus = param;
        },
        "json"
      );
    },
    getlistMemberHeadline(state) {
      window.$.get(
        state.pathAPI + "DB/List_MemberHeadline",
        (param) => {
          state.listMemberHeadline = param;
        },
        "json"
      );
    },
    checkLogin(state, data) {
      window.$.post(
        state.pathAPI + "member/checkLogin",
        data,
        async (param) => {
          state.listUserLogin = param;
          if (param.status == "pass") {
            state.stateOnline = "online";
            this.dispatch("getDefPeriod");
            this.dispatch("getlistMember", 500);
            this.dispatch("getlistMemberAdmin");
            this.dispatch("getlistMemberLose");
            this.dispatch("getlistPeriodAll");
            // this.dispatch("getlistMemberReport");
            this.dispatch("getlistPrename");
            await this.dispatch("getlistProvince");
            await this.dispatch("getlistDistrict");
            await this.dispatch("getlistLocality");
            await this.dispatch("getlistVillate");
            this.dispatch("getlistMemberType");
            this.dispatch("getlistMemberStatus");
            this.dispatch("getlistMemberHeadline");
          } else {
            state.stateOnline = "offline";
          }
          window.Swal.fire(param.title, param.html, param.icon);
        },
        "json"
      );
    },
  },
  actions: {
    getlistPeriodOpen({ state }) {
      return window.$.get(
        state.pathAPI + "DB/List_Period_Open",
        (res) => {
          return (state._getlistPeriodOpen = res);
        },
        "json"
      );
    },
    setMoneyPeriodLose({ state },param) {
      return window.$.post(
        state.pathAPI + "DB/setMoneyPeriodLose",
        { money_for_mem_lose: param },
        (res) => res,
        "json"
      );
    },
    getDefPeriod({ commit }) {
      commit("getDefPeriod");
    },
    getlistPeriod({ commit }) {
      commit("getlistPeriod");
    },
    getlistPeriodAll({ commit }) {
      commit("getlistPeriodAll");
    },
    getlistClub({ commit }) {
      commit("getlistClub");
    },
    getlistMember({ commit, limit }) {
      commit("getlistMember", limit);
    },
    getlistMemberReport({ commit }) {
      commit("getlistMemberReport");
    },
    getlistMemberAdmin({ commit }) {
      commit("getlistMemberAdmin");
    },
    getlistMemberLose({ commit }) {
      commit("getlistMemberLose");
    },
    getlistMemberPayLocality({ commit }) {
      commit("getlistMemberPayLocality");
    },
    getlistPrename({ commit }) {
      commit("getlistPrename");
    },
    getlistProvince({ commit }) {
      commit("getlistProvince");
    },
    getlistDistrict({ commit }) {
      commit("getlistDistrict");
    },
    getlistLocality({ commit }) {
      commit("getlistLocality");
    },
    getlistVillate({ commit }) {
      commit("getlistVillate");
    },
    getlistMemberType({ commit }) {
      commit("getlistMemberType");
    },
    getlistMemberStatus({ commit }) {
      commit("getlistMemberStatus");
    },
    getlistMemberHeadline({ commit }) {
      commit("getlistMemberHeadline");
    },
    checkLogin({ commit }, data) {
      commit("checkLogin", data);
    },
    setMoneyPeriod({ commit, state }, data) {
      window.$.post(
        state.pathAPI + "DB/set_updateperiod",
        data,
        (param) => {
          window.Swal.fire({
            title: param.title,
            html: param.html,
            icon: param.icon,
            timer: 1500,
            showConfirmButton: false,
            position: "top-end",
          });
          commit("getlistPeriodAll");
        },
        "json"
      );
    },
  },
  getters: {
    getDefPeriod(state) {
      return state.DefPer;
    },
    getlistClub(state) {
      return state.listClub;
    },
    getlistPeriod(state) {
      return state.listPeriod;
    },
    getlistPeriodAll(state) {
      return state.listPeriodAll;
    },
    getlistMember(state) {
      let listMember = Array();
      let i = 0;
      let mem_head_arr = Array();
      let mem_head_name = "";
      state.listMember.forEach((ele) => {
        mem_head_arr = state.listVillate.find(
          (ele2) =>
            ele2.ProID +
              "|" +
              ele2.DisID +
              "|" +
              ele2.LocID +
              "|" +
              ele2.vil_id ==
            ele.mem_head_line
        );
        //console.log(mem_head_arr);
        if (mem_head_arr !== undefined) {
          mem_head_name =
            mem_head_arr.DisName +
            "," +
            mem_head_arr.LocName +
            "," +
            mem_head_arr.vil_name;
        }
        listMember[i++] = Object.assign(ele, {
          mem_head_name: mem_head_name,
        });
      });
      return state.listMember;
    },
    getlistMemberReport(state) {
      return state.listMemberReport;
    },
    getlistMemberPayLocality(state) {
      return state.listMemberPayLocality;
    },
    getlistMemberAdmin(state) {
      return state.listMemberAdmin;
    },
    getlistMemberLose(state) {
      return state.listMemberLose;
    },
    getlistPrename(state) {
      return state.listPrename;
    },
    getlistProvince(state) {
      return state.listProvince;
    },
    getlistDistrict: (state) => (data) => {
      if (state.listDistrict.length > 0) {
        return state.listDistrict.filter((val) => val.ProID == data.ProID);
      }
    },
    getlistLocality: (state) => (data) => {
      if (state.listLocality.length > 0) {
        return state.listLocality.filter(
          (val) => val.ProID == data.ProID && val.DIsID == data.DisID
        );
      }
    },
    getlistVillate: (state) => (data) => {
      if (state.listVillate.length > 0) {
        return state.listVillate.filter(
          (val) =>
            val.ProID == data.ProID &&
            val.DisID == data.DisID &&
            val.LocID == data.LocID
        );
      }
    },
    getlistVillateAll(state) {
      //console.log("length=", state.listVillate.length);
      if (state.listVillate.length > 0) {
        let arrayLoc = Array();
        //let i = 0;
        state.listVillate.forEach((element) => {
          arrayLoc.push(
            Object.assign(element, {
              vloc_id:
                element.ProID +
                "|" +
                element.DIsID +
                "|" +
                element.LocID +
                "|" +
                element.vil_id,
              vloc_name:
                element.ProName +
                "->" +
                element.DisName +
                "->" +
                element.LocName +
                "->" +
                element.vil_name,
            })
          );
        });
        return arrayLoc;
      }
    },
    getlistMemberType(state) {
      return state.listMemberType;
    },
    getlistMemberStatus(state) {
      return state.listMemberStatus;
    },
    getlistMemberHeadline(state) {
      return state.listMemberHeadline;
    },
    checkLogin(state) {
      return state.listUserLogin;
    },
    number_format: (state) => (number) => {
      state;
      return number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
});
