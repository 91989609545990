<template>
  <div class="text-center" id="pagelogin">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          เข้าสู่ระบบ
        </v-btn>
      </template>

      <form @submit.prevent="submitData()">
        <v-card>
          <v-card-title class="font-main grey lighten-2">
            <v-icon>mdi-key-chain</v-icon> เข้าสู่ระบบ
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="str_username"
              label="ชื่อผู้ใช้งาน"
              prepend-inner-icon="mdi-account"
              required
            ></v-text-field>
            <v-text-field
              type="password"
              v-model="str_password"
              label="รหัสผ่าน"
              prepend-inner-icon="mdi-lock"
              required
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" type="submit">เข้าสู่ระบบ</v-btn>
            <v-btn @click="clear">กรอกใหม่</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    str_username: "",
    str_password: "",
  }),

  methods: {
    submitData() {
      this.$store.dispatch("checkLogin", this.$data);
    },
    clear() {
      this.str_username = "";
      this.str_password = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--dark,
.v-application {
  background: white !important;
  color: #ffffff;
}
</style>
